@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

.App {
  /* text-align: center; */
  height: 100vh;
}

body {
  font-family: 'Poppins', sans-serif;
}
